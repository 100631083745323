import React from 'react';

import { FormattedMessage } from 'gatsby-plugin-intl';

import './AccousticNormPopupPage.scss';

const AccousticNormPopupPage = () => (
  <div className="acoustic-norm-popup-page">
    <h2>
      <FormattedMessage id="acousticNormPageTitle" />
    </h2>
    <p className="paragraph description-text">
      <FormattedMessage id="acousticNormPageDesc" />
    </p>
    <h2>
      <FormattedMessage id="acousticNormPageSection1Title" />
    </h2>
    <p className="paragraph description-text">
      <FormattedMessage id="acousticNormPageSection1Desc" />
    </p>
    <h2>
      <FormattedMessage id="acousticNormPageSection2Title" />
    </h2>
    <p className="paragraph description-text">
      <FormattedMessage id="acousticNormPageSection2Desc" />
    </p>
    <h2>
      <FormattedMessage id="acousticNormPageSection3Title" />
    </h2>
    <p className="paragraph description-text">
      <FormattedMessage id="acousticNormPageSection3Desc" />
    </p>
  </div>
);

export default AccousticNormPopupPage;
