import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import './CloseModal.scss';

const CloseModal = ({ close }) => (
  <div className="close-modal">
    <Link to={close} className="not-animated">
      <img src="/images/close-dark.svg" alt="close-modal" />
    </Link>
  </div>
);

CloseModal.propTypes = {
  close: PropTypes.string.isRequired,
};

export default CloseModal;
