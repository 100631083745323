import React from 'react';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';

import CloseModal from 'components/CloseModal';
import AccousticNormPopupPage from 'components/AccousticNormPopupPage';

const AcousticNormPopup = () => (
  <ModalRoutingContext.Consumer>
    {({ closeTo }) => (
      <>
        <div>
          <CloseModal close={closeTo} />
        </div>
        <AccousticNormPopupPage />
      </>
    )}
  </ModalRoutingContext.Consumer>
);

export default AcousticNormPopup;
